<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="title1">Intelligent Driver Application Solution
      </div>
      <div class="top-des">
        Our intelligent driver application is a top-notch technological solution that revolutionizes the way our drivers
        operate, ensuring efficient and seamless delivery processes. Harnessing the power of cutting-edge technology, this
        solution empowers our drivers with advanced tools and features, enhancing their capabilities and enabling them to
        deliver exceptional service.
      </div>
      <div class="paiban">
        <div class="images">
          <img class="two-img" style="border-radius: 8px 0 0 8px;" src="../../../assets/images/home/driver3.png" alt="">
          <img class="two-img" style="border-radius: 0 8px 8px 0;" src="../../../assets/images/home/driver4.png" alt="">
        </div>
        <div class="content" style="margin-left:80px;">
          <div class="title">Real-time Route Optimization</div>
          <div class="des">
            Efficient routing and navigation are essential for timely deliveries. Our driver app integrates with GPS and
            mapping systems to provide drivers with optimal routes based on traffic conditions, delivery priorities, and
            customer locations. With turn-by-turn directions and real-time traffic updates, drivers can navigate
            efficiently and avoid unnecessary delays.
          </div>
        </div>
      </div>
      <div class="paiban">
        <div class="content" style="margin-right:80px;">
          <div class="title">Real-Time Order Management
          </div>
          <div class="des">
            ADS enable drivers to receive and manage orders or tasks in real-time through their applications. They provide
            drivers with detailed information about pickup and delivery locations, order specifications, customer
            preferences, and any special instructions. Real-time order management helps drivers stay organized, prioritize
            tasks, and streamline their workflow.
          </div>
        </div>
        <div class="images">
          <img class="two-img" style="border-radius: 8px 0 0 8px;" src="../../../assets/images/home/driver7.png" alt="">
          <img class="two-img" style="border-radius: 0 8px 8px 0;" src="../../../assets/images/home/driver8.png" alt="">
        </div>
      </div>
      <div class="paiban">
        <div class="images">
          <img class="two-img" style="border-radius: 8px 0 0 8px;" src="../../../assets/images/home/driver5.png" alt="">
          <img class="two-img" style="border-radius: 0 8px 8px 0;" src="../../../assets/images/home/driver6.png" alt="">
        </div>
        <div class="content" style="margin-left:80px;">
          <div class="title">Seamless Communication and Updates</div>
          <div class="des">
            The application facilitates seamless communication between drivers, dispatchers, and customers. Drivers
            receive real-time updates, including new orders, route changes, or customer requests, ensuring they stay
            informed and can adapt accordingly.
          </div>
        </div>
      </div>
      <div class="paiban">
        <div class="content" style="margin-right:80px;">
          <div class="title"> Active Engagement Within Local Neighborhoods</div>
          <div class="des">
            In addition, our innovative approach eliminates the need for exclusively employing local drivers with in-depth
            knowledge of the local area. This opens up exciting driver job opportunities within our local community. With
            our driver application, anyone can join our team and start delivering with ease. This not only benefits our
            community by creating job opportunities but also ensures that our drivers can focus on delivering with
            excellence while leveraging the intelligence of our technology. It's an awesome combination of community
            empowerment and advanced logistics solutions.
          </div>
        </div>
        <img class="woshou" src="../../../assets/images/home/woshou.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku13.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .top-des {
    font-size: 24px;
    font-weight: 400;
    color: var(--color-text-2);
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
  }

  .title1 {
    text-align: center;
    font-weight: 300;
    font-size: 56px;
    line-height: 53px;
    color: #333333;
  }

  .des {
    font-weight: 400;
    font-size: 24px;
    color: #7D7D7D;
    padding-top: 10px;
  }

  .images {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 8px;
  }

  .woshou {
    border-radius: 8px;

    img {
      width: 560px;
      border-radius: 8px;
    }
  }
}

.paiban {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  .images {

    .two-img {
      width: 270px;
    }
  }
}
</style>
